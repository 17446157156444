import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useTitle } from '@/hooks/useEnv';
import { AuthLayout2 } from '@/layouts/auth';
import { Button } from '@/components/button';
import illustration from '@/assets/img/error-not-found.svg';

const NotFoundPage: React.FC = () => {
  const title = useTitle();

  return (
    <>
      <Helmet>
        <title>{`Halaman Tidak Ditemukan - ${title}`}</title>
      </Helmet>
      <AuthLayout2>
        <section>
          <img src={illustration} alt='illustration' width={350} height={350} className='mx-auto mb-6' />
          <h1 className='text-3xl md:text-4xl lg:text-5xl !leading-tight font-semibold text-center mb-6'>Halaman Tidak Ditemukan</h1>
          <p className='md:text-lg lg:text-xl text-center text-general-500'>Maaf, halaman yang Anda cari tidak tersedia. Silakan kembali ke beranda atau coba pencarian lainnya.</p>
        </section>

        <section>
          <p className='text-center mt-8 mb-4 lg:mb-0'>
            <Link to='/' className='inline-block'>
              <Button variant='primary'>
                Kembali ke Beranda
              </Button>
            </Link>
          </p>
        </section>
      </AuthLayout2>
    </>
  );
}

export default NotFoundPage;