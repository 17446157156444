import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';

const RoleRedirect: React.FC<{path: string}> = ({ path }) => {
  const role = useSelector((state: RootState) => state.user.role);
  const target = role === 'kontributor-data' ? `/member${path}` : `/admin${path}`;
  return <Navigate to={target} replace />;
};

interface RouteConfig {
  title: string;
  path: string;
  component?: React.ComponentType;
  routes?: RouteConfig[];
};

const routes: RouteConfig[] = [
  {
    title: 'Login',
    path: '/login',
    component: lazy(() => import('@/pages/auth/login')),
  },
  {
    title: 'Forgot Password',
    path: '/forgot',
    component: lazy(() => import('@/pages/auth/forgotPassword')),
    routes: [
      {
        title: 'Forgot Password Verification',
        path: '/verify',
        component: lazy(() => import('@/pages/auth/forgotPasswordVerification')),
      },
      {
        title: 'Create New Password',
        path: '/create',
        component: lazy(() => import('@/pages/auth/forgotPasswordCreate')),
      },
    ],
  },
  {
    title: 'Registration',
    path: '/register',
    component: lazy(() => import('@/pages/auth/register')),
    routes: [
      {
        title: 'Registration Verification',
        path: '/verify',
        component: lazy(() => import('@/pages/auth/registerVerification')),
      },
      {
        title: 'Registration Verification Result',
        path: '/result',
        component: lazy(() => import('@/pages/auth/registerVerificationResult')),
      },
    ],
  },
  {
    title: 'Account',
    path: '/account',
    component: lazy(() => import('@/pages/auth/account')),
    routes: [
      {
        title: 'Account Verification',
        path: '/verify',
        component: lazy(() => import('@/pages/auth/accountVerification')),
        routes: [
          {
            title: 'Account Verification Success',
            path: '/result',
            component: lazy(() => import('@/pages/auth/accountVerificationResult')),
          },
        ]
      },
    ],
  },
  {
    title: 'Dashboard',
    path: '/:role?',
    component: () => <RoleRedirect path='/dashboard' />,
    routes: [
      {
        title: 'Dashboard',
        path: '/dashboard',
        component: lazy(() => import('@/pages/main/dasbor')),
        routes: [
          {
            title: 'K/L',
            path: '/ministry',
            component: lazy(() => import('@/pages/main/dasbor/adminKL')),
          },
          {
            title: 'Provinsi',
            path: '/province',
            component: lazy(() => import('@/pages/main/dasbor/adminPV')),
          },
          {
            title: 'Kab/Kota',
            path: '/city',
            component: lazy(() => import('@/pages/main/dasbor/adminKT')),
          },
        ]
      },
      {
        title: 'Registered User',
        path: '/registered-user',
        component: lazy(() => import('@/pages/main/pengguna-terdaftar')),
        routes: [
          {
            title: 'Detail Registered User',
            path: '/:id',
            component: lazy(() => import('@/pages/main/pengguna-terdaftar/detail')),
          },
          {
            title: 'Create Registered User',
            path: '/create',
            component: lazy(() => import('@/pages/main/pengguna-terdaftar/create')),
          },
        ]
      },
      {
        title: 'Work Plan',
        path: '/work-plan',
        component: lazy(() => import('@/pages/main/rencana-kerja')),
        routes: [
          {
            title: 'CBT',
            path: '/cbt',
            component: lazy(() => import('@/pages/main/rencana-kerja/cbt')),
          },
          {
            title: 'RCBT',
            path: '/rcbt',
            component: lazy(() => import('@/pages/main/rencana-kerja/rcbt')),
          },
        ]
      },
      {
        title: 'Machine Learning',
        path: '/machine-learning',
        component: () => <RoleRedirect path='/machine-learning/data-latih' />,
        routes: [
          {
            title: 'Data Latih',
            path: '/data-latih',
            component: lazy(() => import('@/pages/main/machine-learning/data-latih')),
            routes: [
              {
                title: 'Create Data Latih',
                path: '/create',
                component: lazy(() => import('@/pages/main/machine-learning/data-latih/create')),
              },
              {
                title: 'Update Data Latih',
                path: '/:id/edit',
                component: lazy(() => import('@/pages/main/machine-learning/data-latih/edit')),
              },
            ]
          },
          {
            title: 'Data Uji',
            path: '/data-uji',
            component: lazy(() => import('@/pages/main/machine-learning/data-uji')),
            routes: [
              {
                title: 'Create Data Uji',
                path: '/create',
                component: lazy(() => import('@/pages/main/machine-learning/data-uji/create')),
              },
              {
                title: 'Update Data Uji',
                path: '/:id/edit',
                component: lazy(() => import('@/pages/main/machine-learning/data-uji/edit')),
              },
            ]
          },
          {
            title: 'Data Model',
            path: '/data-model',
            component: lazy(() => import('@/pages/main/machine-learning/data-model')),
          },
        ]
      },
      {
        title: 'Analysis',
        path: '/analysis',
        component: () => <RoleRedirect path='/analysis/tagging/national' />,
        routes: [
          {
            title: 'Tagging',
            path: '/tagging',
            component: () => <RoleRedirect path='/analysis/tagging/national' />,
            routes: [
              {
                title: 'National',
                path: '/national',
                component: lazy(() => import('@/pages/main/analisis/tagging/nasional/')),
              },
              {
                title: 'Regional',
                path: '/regional',
                component: lazy(() => import('@/pages/main/analisis/tagging/daerah/')),
              },
            ]
          },
          {
            title: 'Achievement',
            path: '/achievement',
            component: () => <RoleRedirect path='/analysis/achievement/national' />,
            routes: [
              {
                title: 'National',
                path: '/national',
                component: lazy(() => import('@/pages/main/analisis/capaian/nasional/')),
              },
              {
                title: 'Regional',
                path: '/regional',
                component: lazy(() => import('@/pages/main/analisis/capaian/daerah/')),
              },
            ]
          },
          {
            title: 'Sukuk & Bond',
            path: '/sukuk-bond',
            component: lazy(() => import('@/pages/main/analisis/sukuk-bond/')),
          },
          {
            title: 'Scoring',
            path: '/scoring',
            component: lazy(() => import('@/pages/main/analisis/skoring/')),
          },
        ]
      },
      {
        title: 'Interoperability Data',
        path: '/interoperability',
        component: () => <RoleRedirect path='/interoperability/get' />,
        routes: [
          {
            title: 'Get Data',
            path: '/get',
            component: lazy(() => import('@/pages/main/interoperabilitas-data/peroleh-data')),
            routes: [
              {
                title: 'RKA',
                path: '/rka',
                component: () => <RoleRedirect path='/interoperability/get' />,
                routes: [
                  {
                    title: 'History',
                    path: '/:id',
                    component: lazy(() => import('@/pages/main/interoperabilitas-data/peroleh-data/detail')),
                  }
                ]
              },
              {
                title: 'Achievement',
                path: '/achievement',
                component: () => <RoleRedirect path='/interoperability/get' />,
                routes: [
                  {
                    title: 'History',
                    path: '/:id',
                    component: lazy(() => import('@/pages/main/interoperabilitas-data/peroleh-data/detail')),
                  }
                ]
              },
            ]
          },
          {
            title: 'Share Data',
            path: '/share',
            component: lazy(() => import('@/pages/main/interoperabilitas-data/berbagi-data')),
            routes: [
              {
                title: 'Create API',
                path: '/create',
                component: lazy(() => import('@/pages/main/interoperabilitas-data/berbagi-data/create')),
              },
              {
                title: 'Detail API',
                path: '/:id',
                component: () => <RoleRedirect path='/interoperability/share' />,
                routes: [
                  {
                    title: 'Edit API',
                    path: '/edit',
                    component: lazy(() => import('@/pages/main/interoperabilitas-data/berbagi-data/edit')),
                  }
                ]
              },
            ]
          },
        ]
      },
    ]
  },
];

export default routes;