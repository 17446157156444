import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CountdownState {
  timeLeft: number;
};

const initialState: CountdownState = {
  timeLeft: 0,
};

const countdownSlice = createSlice({
  name: 'countdown',
  initialState,
  reducers: {
    setTimeLeft(state, action: PayloadAction<number>) {
      state.timeLeft = action.payload;
    },
    decrementTimeLeft(state) {
      if (state.timeLeft > 0) {
        state.timeLeft -= 1;
      }
    },
  },
});

export const { setTimeLeft, decrementTimeLeft } = countdownSlice.actions;
export default countdownSlice.reducer;