import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  id: string;
  name: string;
  email: string;
  image: string;
  role: string;
  token: string;
  is_new: boolean | null;
  is_verify: boolean | null;
  reason: string[];
};

const initialState: UserState = {
  id: '',
  name: '',
  email: '',
  image: '',
  role: '',
  token: '',
  is_new: null,
  is_verify: null,
  reason: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<Partial<UserState>>) => {
      return { ...state, ...action.payload };
    },
    clearUser: (state) => {
      return initialState;
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;