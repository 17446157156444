import { ReactNode } from 'react';
import loader from '@/assets/icon/loading.svg';
import loaderGeneral from '@/assets/icon/loading-general.svg';
import loaderPrimary from '@/assets/icon/loading-primary.svg';
import loaderSuccess from '@/assets/icon/loading-success.svg';
import loaderInfo from '@/assets/icon/loading-info.svg';
import loaderWarning from '@/assets/icon/loading-warning.svg';
import loaderDanger from '@/assets/icon/loading-danger.svg';

type ButtonProps = {
  type?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  loading?: boolean | 'none';
  loadingVariant?: string;
  className?: string;
  variant?: 'default' | 'general' | 'primary' | 'success' | 'info' | 'warning' | 'danger' | 'light' | 'outline-general' | 'outline-primary' | 'outline-success' | 'outline-info' | 'outline-warning' | 'outline-danger';
  children: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Button: React.FC<ButtonProps> = ({ type = 'button', disabled, loading = 'none', loadingVariant, className, variant = 'default', children, onClick }) => {
  let variantStyle = ''
  let outlineStyle = 'lg:hover:text-white border-2'

  switch (variant) {
    case 'general':
      variantStyle = 'text-white bg-general-700 lg:hover:bg-general-500'
      break
    case 'primary':
      variantStyle = 'text-white bg-primary-700 lg:hover:bg-primary-500'
      break
    case 'success':
      variantStyle = 'text-white bg-success-500 lg:hover:bg-success-600'
      break
    case 'info':
      variantStyle = 'text-white bg-info-500 lg:hover:bg-info-600'
      break
    case 'warning':
      variantStyle = 'text-white bg-warning-500 lg:hover:bg-warning-600'
      break
    case 'danger':
      variantStyle = 'text-white bg-danger-500 lg:hover:bg-danger-600'
      break
    case 'light':
      variantStyle = 'text-primary-700 bg-white lg:hover:bg-primary-50'
      break
    case 'outline-general':
      variantStyle = `text-general-600 border-general-600 lg:hover:bg-general-600 ${outlineStyle}`
      break
    case 'outline-primary':
      variantStyle = `text-primary-700 border-primary-700 lg:hover:bg-primary-700 ${outlineStyle}`
      break
    case 'outline-success':
      variantStyle = `text-success-600 border-success-600 lg:hover:bg-success-600 ${outlineStyle}`
      break
    case 'outline-info':
      variantStyle = `text-info-500 border-info-500 lg:hover:bg-info-500 ${outlineStyle}`
      break
    case 'outline-warning':
      variantStyle = `text-warning-500 border-warning-500 lg:hover:bg-warning-500 ${outlineStyle}`
      break
    case 'outline-danger':
      variantStyle = `text-danger-600 border-danger-600 lg:hover:bg-danger-600 ${outlineStyle}`
      break
    default:
      break
  }

  let variantLoading = loader

  switch (loadingVariant) {
    case 'general':
      variantLoading = loaderGeneral
      break
    case 'primary':
      variantLoading = loaderPrimary
      break
    case 'success':
      variantLoading = loaderSuccess
      break
    case 'info':
      variantLoading = loaderInfo
      break
    case 'warning':
      variantLoading = loaderWarning
      break
    case 'danger':
      variantLoading = loaderDanger
      break
    case 'light':
      variantLoading = loaderPrimary
      break
    default:
      break
  }

  return (
    <button type={type} disabled={disabled} className={`flex items-center justify-center gap-3 w-full rounded-xl py-3 md:py-4 px-5 md:px-6 font-medium focus-visible:outline-none lg:transition disabled:pointer-events-none [&.disabled]:pointer-events-none disabled:bg-gray-300 [&.disabled]:bg-gray-300 ${variantStyle} ${className || ''}`} onClick={onClick}>
      <span>{children}</span>
      {loading !== 'none' &&
        <div className={`transition-all ${!loading ? '-mr-9 opacity-0' : ''}`}>
          <img src={variantLoading} alt='loading' width={24} height={24} className='animate-spin' />
        </div>
      }
    </button>
  )
}