import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useTitle } from '@/hooks/useEnv';
import { AuthLayout2 } from '@/layouts/auth';
import { Button } from '@/components/button';
import illustration from '@/assets/img/error-forbidden.svg';

interface ForbiddenPageProps {
  to?: string;
  text?: string;
}

const ForbiddenPage: React.FC<ForbiddenPageProps> = ({ to = '/', text = 'Beranda' }) => {
  const title = useTitle();

  const pageStyle = `
    body {
      font-family: 'Poppins', sans-serif;
      color: black;
    }
  `;

  return (
    <>
      <Helmet>
        <title>{`Akses Ditolak - ${title}`}</title>
        <style>{pageStyle}</style>
      </Helmet>
      <AuthLayout2>
        <section>
          <img src={illustration} alt='illustration' width={350} height={350} className='mx-auto mb-6' />
          <h1 className='text-3xl md:text-4xl lg:text-5xl !leading-tight font-semibold text-center mb-6'>Akses Ditolak</h1>
          <p className='md:text-lg lg:text-xl text-center text-general-500'>Maaf, Anda tidak memiliki izin untuk mengakses halaman ini. Silakan kembali ke halaman sebelumnya atau hubungi administrator jika Anda memerlukan bantuan.</p>
        </section>

        <section>
          <p className='text-center mt-8 mb-4 lg:mb-0'>
            <Link to={to} className='inline-block'>
              <Button variant='primary'>
                Kembali ke {text}
              </Button>
            </Link>
          </p>
        </section>
      </AuthLayout2>
    </>
  );
}

export default ForbiddenPage;