import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserDataState {
  pending_user: number | null;
  active_user: number | null;
  lembaga_user: number | null;
  province_user: number | null;
  city_user: number | null;
};

const initialState: UserDataState = {
  pending_user: null,
  active_user: null,
  lembaga_user: null,
  province_user: null,
  city_user: null,
};

const userDataSlice = createSlice({
  name: 'user_data',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<Partial<UserDataState>>) => {
      return { ...state, ...action.payload };
    },
    clearUserData: (state) => {
      return initialState;
    },
  },
});

export const { setUserData, clearUserData } = userDataSlice.actions;
export default userDataSlice.reducer;