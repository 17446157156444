import { configureStore } from '@reduxjs/toolkit';
import { createTransform, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from '@/app/rootReducer';
import CryptoJS from 'crypto-js';

const title = process.env.REACT_APP_BASE_TITLE || 'react-app'
const name = title.toLowerCase().split(' ').join('-')
const secret = process.env.REACT_APP_SECRET || 'secret-key'

const encrypt = (inboundState: any) => {
  const encryptedState = CryptoJS.AES.encrypt(
    JSON.stringify(inboundState),
    secret
  ).toString();
  return encryptedState;
};

const decrypt = (outboundState: any) => {
  const bytes = CryptoJS.AES.decrypt(outboundState, secret);
  const decryptedState = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedState;
};

const encryptor = createTransform(
  (inboundState: any) => encrypt(inboundState),
  (outboundState: any) => decrypt(outboundState)
);

const persistConfig: any = {
  key: name,
  storage,
  transforms: [encryptor],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;