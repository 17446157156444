import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PanelState {
  sidebar: boolean;
};

const initialState: PanelState = {
  sidebar: true,
};

const panelSlice = createSlice({
  name: 'panel',
  initialState,
  reducers: {
    setPanel: (state, action: PayloadAction<Partial<PanelState>>) => {
      return { ...state, ...action.payload };
    },
    clearPanel: (state) => {
      return initialState;
    },
  },
});

export const { setPanel, clearPanel } = panelSlice.actions;
export default panelSlice.reducer;