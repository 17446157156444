import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DataLatihType = {
    id: number;
    name_output: string;
    name_urs_output: string;
    jenis: string;
    sektor: string;
    kategori: string;
    is_verify: boolean;
}

type Pagination = {
    total: number;
    per_page: number;
    current_page: number;
}

type DataLatihTableState = {
    name: boolean;
    type: boolean;
    verification: boolean;
    sector: boolean;
    category: boolean;
    get_again: number;
    data: DataLatihType[];
    pagination: Pagination;
}

const initialState: DataLatihTableState = {
    name: true,
    type: true,
    verification: true,
    sector: true,
    category: true,
    get_again: 0,
    data: [],
    pagination: {
        total: 0,
        per_page: 10,
        current_page: 1,
    },
};

const dataLatihTableSlice = createSlice({
  name: 'data_latih_table',
  initialState,
  reducers: {
    setDataLatihTable: (state, action: PayloadAction<Partial<DataLatihTableState>>) => {
      return {
        ...state,
        ...action.payload,
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
      };
    },
    clearDataLatihTable: (state) => {
      return {
        ...initialState,
        pagination: {
          ...initialState.pagination,
        },
      };
    },
  },
});

export const { setDataLatihTable, clearDataLatihTable } = dataLatihTableSlice.actions;
export default dataLatihTableSlice.reducer;