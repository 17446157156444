import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import { Loading1 } from '@/components/loading';

interface RouteConfig {
  path: string;
  component?: React.ComponentType<any>;
  routes?: RouteConfig[];
}

const renderRoutes = (routes: RouteConfig[], basePath = ''): React.ReactNode[] => {
  return routes.map((route) => {
    const fullPath = `${basePath}${route.path}`;
    const isDashboardRoute = basePath === '/' && (route.path === '/:role?' || route.routes?.some(r => r.path === '/:role?'));

    const RouteComponent = route.component ? (
      isDashboardRoute ? (
        <route.component />
      ) : (
        <Suspense key={fullPath} fallback={<Loading1 />}>
          <route.component />
        </Suspense>
      )
    ) : null;

    return (
      <React.Fragment key={fullPath}>
        {route.component && (
          <Route path={fullPath} element={RouteComponent} />
        )}
        {route.routes && renderRoutes(route.routes, fullPath)}
      </React.Fragment>
    );
  });
};

export default renderRoutes;
