import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { HeadLayout } from '@/layouts/head';
import { useBaseUrl } from '@/hooks/useEnv';
import bg from '@/assets/img/bg.svg';
import logoBKF from '@/assets/img/logo-bkf.svg';

interface AuthLayoutProps {
  children: ReactNode;
}

export const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  return (
    <HeadLayout>
      <div className='relative h-screen overflow-hidden'>
        <main className='overflow-y-auto h-full relative z-[1]'>
          <div className='w-full max-w-3xl lg:max-w-4xl mx-auto min-h-full flex items-start sm:items-center justify-center sm:p-8'>
            <div className='w-full bg-white rounded-xl p-6 md:p-10 lg:p-16 flex items-center max-sm:my-2 max-sm:min-h-[90vh]'>
              <div className='w-full'>
                <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
                  {children}
                </GoogleOAuthProvider>
              </div>
            </div>
          </div>
        </main>

        <div className='absolute top-0 left-0 w-full h-full max-sm:hidden'>
          <img src={bg} alt='bg' width={1440} height={1024} className='w-full h-full object-cover' />
        </div>
      </div>
    </HeadLayout>
  );
};

export const AuthLayout1: React.FC<AuthLayoutProps> = ({ children }) => {
  return (
    <AuthLayout>
      <section className='mb-8'>
        <Link to={useBaseUrl()}>
          <img src={logoBKF} alt='logo' width={175} height={48} className='inline w-auto h-14' />
        </Link>
      </section>

      {children}
    </AuthLayout>
  );
};

export const AuthLayout2: React.FC<AuthLayoutProps> = ({ children }) => {
  return (
    <AuthLayout>
      <section className='mb-8 text-center'>
        <Link to={useBaseUrl()}>
          <img src={logoBKF} alt='logo' width={175} height={48} className='inline w-auto h-14' />
        </Link>
      </section>

      {children}
    </AuthLayout>
  );
};