import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import routes from '@/routes';
import renderRoutes from '@/routes/render';
import ForbiddenPage from '@/pages/error/forbidden';
import InternalServerPage from '@/pages/error/internalServer';
import PublicationDetail from '@/pages/landing/publicationDetail';
import NotFoundPage from '@/pages/error/notFound';

function App() {
  const NavigateToLandingPage = () => {
    useEffect(() => {
      window.location.href = '/beranda/';
    }, []);
    return null;
  };

  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/" element={<NavigateToLandingPage />} />
          <Route path="/publikasi/:id" element={<PublicationDetail />} />
          {renderRoutes(routes)}
          <Route path="/forbidden" element={<ForbiddenPage />} />
          <Route path="/internal-server-error" element={<InternalServerPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
