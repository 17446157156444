import { useEffect } from "react";

interface NavbarProps {
  active?: string;
}

const Navbar: React.FC<NavbarProps> = ({ active }) => {
  useEffect(() => {
    document.querySelector('.lang-id')?.setAttribute('onclick',`doGTranslate('id|id')`)
    document.querySelector('.lang-us')?.setAttribute('onclick',`doGTranslate('id|en')`)
  }, []);

  return (
    <nav className="navbar bg-white fixed-top navbar-expand-lg">
      <div className="container">
        <a className="navbar-brand" href="/beranda/">
          <img src="/assets/img/logo.svg" alt="Climate Budget Tagging - CBT" width="182" height="50" className="object-fit-contain" />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto mb-2 mb-lg-0 fw-semibold">
            <li className="nav-item">
              <a className={`nav-link ${active === 'beranda' ? 'active' : ''}`} href="/beranda/">Beranda</a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${active === 'tentang' ? 'active' : ''}`} href="/tentang/">Tentang</a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${active === 'publikasi' ? 'active' : ''}`} href="/publikasi/">Pusat Pembelajaran</a>
            </li>
            <li className="nav-item"><a className="nav-link" href="/beranda/#charts">Jelajah Data</a></li>
          </ul>
          <ul className="navbar-nav mb-2 mb-lg-0">
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle fw-semibold w-100 text-lg-end" id="navbarDropdownLang" href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false"><span className="fi fi-id fis me-1 rounded-circle"></span> ID</a>
              <ul id="lang-dropdown" className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownLang">
                <li><a className="dropdown-item lang-id" href="#!"><span className="fi fi-id fis me-2 rounded-circle"></span> Bahasa Indonesia</a></li>
                <li><a className="dropdown-item lang-us" href="#!"><span className="fi fi-us fis me-2 rounded-circle"></span> English</a></li>
              </ul>
              <div id="google_translate_element2"></div>
            </li>
          </ul>
          <div className="d-flex">
            <a id="btn-login" className="btn btn-dark-blue rounded-4 ms-lg-3 ms-xl-4 link-login" href="/login" role="button">Masuk</a>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar;