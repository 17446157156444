import { combineReducers } from '@reduxjs/toolkit';
import reducerCountdown from '@/features/sliceCountdown';
import reducerPanel from '@/features/slicePanel';
import reducerUser from '@/features/sliceUser';
import reducerUserData from '@/features/sliceUserData';
import reducerUserTable from '@/features/sliceUserTable';
import reducerCbtTable from '@/features/sliceCbtTable';
import reducerRcbtTable from '@/features/sliceRcbtTable';
import reducerDataLatihTable from '@/features/sliceDataLatihTable';
import reducerDataUjiTable from '@/features/sliceDataUjiTable';
import reducerDataModelTable from '@/features/sliceDataModelTable';
import reducerCbtComponentTable from '@/features/sliceCbtComponentTable';

const rootReducer = combineReducers({
  countdown: reducerCountdown,
  panel: reducerPanel,
  user: reducerUser,
  user_data: reducerUserData,
  user_table: reducerUserTable,
  cbt_table: reducerCbtTable,
  rcbt_table: reducerRcbtTable,
  data_latih_table: reducerDataLatihTable,
  data_uji_table: reducerDataUjiTable,
  data_model_table: reducerDataModelTable,
  data_cbt_component_table: reducerCbtComponentTable,
});

export default rootReducer;