import { useLocation } from 'react-router-dom';

const title = process.env.REACT_APP_BASE_TITLE || '';
const url = process.env.REACT_APP_BASE_URL || '';

export const useTitle = () => {
  return title;
};

export const useBaseUrl = () => {
  return url;
};

export const useCurrentUrl = () => {
  const location = useLocation();
  return url + location.pathname;
};