const Footer: React.FC = () => {
  return (
    <footer>
      <section className="mt-5">
        <div className="container pt-md-5">
          <div className="row">
            <div className="col-lg-5 mx-auto mb-4 text-muted">
              <img className="mb-4" src="../assets/img/logo.png" alt="logo" width="182" height="50" loading="lazy" />
              <p className="small">
                BKF cq PKPPIM bertugas untuk melaksanakan analisis, perumusan rekomendasi, dan evaluasi kebijakan perubahan iklim, serta analisis, perumusan rekomendasi kebijakan, koordinasi, pelaksanaan, pemantauan, dan evaluasi kerja sama ekonomi dan keuangan pada forum The Group of Twenty (G20), multilateral, dan Organisation For Economic Co-Operation and Development (OECD).
              </p>
              <a href="#!" className="me-3 text-reset text-decoration-none">
                <i className="ri-linkedin-box-fill fs-4 text-secondary text-opacity-50"></i>
                <span className="visually-hidden">LinkedIn</span>
              </a>
              <a href="#!" className="me-3 text-reset text-decoration-none">
                <i className="ri-twitter-fill fs-4 text-secondary text-opacity-50"></i>
                <span className="visually-hidden">Twitter</span>
              </a>
              <a href="#!" className="me-3 text-reset text-decoration-none">
                <i className="ri-facebook-circle-fill fs-4 text-secondary text-opacity-50"></i>
                <span className="visually-hidden">Facebook</span>
              </a>
              <a href="#!" className="me-3 text-reset text-decoration-none">
                <i className="ri-instagram-fill fs-4 text-secondary text-opacity-50"></i>
                <span className="visually-hidden">Instagram</span>
              </a>
              <a href="#!" className="text-reset text-decoration-none">
                <i className="ri-youtube-fill fs-4 text-secondary text-opacity-50"></i>
                <span className="visually-hidden">YouTube</span>
              </a>
            </div>
            <div className="col-lg-2 mx-auto mb-4">
              <h3 className="text-dark-blue fs-6 fw-bold mb-4">
                Pintasan
              </h3>
              <p>
                <a href="/beranda/" className="text-reset text-decoration-none">Beranda</a>
              </p>
              <p>
                <a href="/tentang/" className="text-reset text-decoration-none">Tentang</a>
              </p>
              <p>
                <a href="/publikasi/" className="text-reset text-decoration-none">Pusat Pembelajaran</a>
              </p>
              <p>
                <a href="/beranda/#charts" className="text-reset text-decoration-none">Jelajah Data</a>
              </p>
              <p>
                <a href="/login" className="text-reset text-decoration-none link-login">Masuk</a>
              </p>
            </div>
            <div className="col-lg-3 mx-auto mb-4">
              <h3 className="text-dark-blue fs-6 fw-bold mb-4">
                Lainnya
              </h3>
              <p>
                <a href="https://www.kemenkeu.go.id" target="_blank" rel="noreferrer" className="text-reset text-decoration-none">Kementerian Keuangan <i className="ms-1 ri-external-link-line"></i></a>
              </p>
              <p>
                <a href="https://fiskal.kemenkeu.go.id" target="_blank" rel="noreferrer" className="text-reset text-decoration-none">Badan Kebijakan Fiskal <i className="ms-1 ri-external-link-line"></i></a>
              </p>
            </div>
            <div className="col-lg-2 mx-auto mb-4">
              <em className="small">Inisiatif Climate Budget Tagging Didukung Oleh</em>
              <br />
              <br />
              <img className="img-fluid" src="../assets/img/undp.png" alt="UNDP" width="102" height="50" loading="lazy" title="UNDP" />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-dark-blue py-4 mt-auto">
        <div className="container px-1">
          <div className="small m-0 text-white text-center">Hak Cipta Badan Kebijakan Fiskal, Kementerian Keuangan &copy; <span className="copyright">2019</span></div>
        </div>
      </section>

      {/* <section>
        <div id="mapmodal" className="map-modal rounded-4 p-4" style={{left:'0px', top:'0px',zIndex:'10000'}}>
          <a className="path-close" href="javascript:void(0);"><i className="bi bi-x-lg"></i></a>
          <h5>-</h5>
          <p>-</p>
        </div>
      </section> */}
    </footer>
  )
}

export default Footer;