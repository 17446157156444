import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type RcbtType = {
  id: number;
  pemda: {
    name: string;
    sub: string;
  };
  kegiatan: Event;
  sub_kegiatan: Event;
  tag_awal: {
    edited?: Modify;
    items: Tag[];
  };
  tag_rekomendasi: {
    edited?: Modify;
    items: Tag[];
  };
  tingkat_aksi: string;
  status: string;
}

type Event = {
  id: string;
  name: string;
};

type Modify = {
  author: string;
  date: string;
};

type Tag = {
  status: string;
  title: string;
  detail?: string;
};

type Pagination = {
  total: number;
  per_page: number;
  current_page: number;
}

type RcbtTableState = {
  pemda: boolean;
  kegiatan: boolean;
  sub_kegiatan: boolean;
  tag_awal: boolean;
  tag_rekomendasi: boolean;
  tingkat_aksi: boolean;
  status: boolean;
  get_again: number;
  data: RcbtType[];
  pagination: Pagination;
};

const initialState: RcbtTableState = {
  pemda: true,
  kegiatan: true,
  sub_kegiatan: true,
  tag_awal: true,
  tag_rekomendasi: true,
  tingkat_aksi: true,
  status: true,
  get_again: 0,
  data: [],
  pagination: {
    total: 0,
    per_page: 10,
    current_page: 1,
  },
};

const rcbtTableSlice = createSlice({
  name: 'rcbt_table',
  initialState,
  reducers: {
    setRcbtTable: (state, action: PayloadAction<Partial<RcbtTableState>>) => {
      return {
        ...state,
        ...action.payload,
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
      };
    },
    clearRcbtTable: (state) => {
      return {
        ...initialState,
        pagination: {
          ...initialState.pagination,
        },
      };
    },
  },
});

export const { setRcbtTable, clearRcbtTable } = rcbtTableSlice.actions;
export default rcbtTableSlice.reducer;